import { useEffect, useState } from "react";
import { Grid2 as Grid, Pagination, Paper, Typography } from "@mui/material"
import { LoginSessionDTO } from "../../../DTOs/auth/LoginSessionDTO";
import { getActiveSessions, inactiveSession } from "../../../services/loginSessionService";
import { ActiveSessionCard, Dialog, ItemSkeletonCard } from "../../components";
import { toast } from "react-toastify";

const ActiveSessionsRoute = () => {
    const [sessions, setSessions] = useState<LoginSessionDTO[]>([]);
    const [selectedSessionsId, setSelectedSessionsId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);


    const fetchActiveSessions = async () => {
        setLoading(true)
        const { data } = await getActiveSessions()
        setSessions(data.results);
        setLoading(false)
    }

    const handleAcceptInActiveSession = async () => {
        setLoading(true);
        try {
            await inactiveSession(selectedSessionsId);
            const currentSessions = [...sessions];
            const newSessions = currentSessions.filter((s) => s.id !== selectedSessionsId);
            setSessions(newSessions);
            toast.success('نشست غیرفعال شد');
        } catch (error) {
            toast.error('درخواست شما با خطا مواجه شد لطفا بعدا تلاش کنید.');
        } finally {
            setLoading(false);
            setDialogOpen(false);
        }
    }

    useEffect(() => {
        fetchActiveSessions();
    }, [])


    return (
        <>
            <Grid container spacing={2}>
                {loading ? (
                    <>
                        {Array.from({ length: 8 }, (_, i) => i + 1).map(item => (
                            <Grid size={{ xs: 12, md: 6 }} key={item}>
                                <ItemSkeletonCard />
                            </Grid>
                        ))}
                    </>
                ) : (
                    <>
                        {sessions.map(s => (
                            <Grid size={{ xs: 12, md: 6 }} key={s.id}>
                                <Typography>{s.token}</Typography>
                                <ActiveSessionCard session={s} showDeleteIcon={!s.logged_in_session!!}
                                    onPress={() => {
                                        setSelectedSessionsId(s.id!!)
                                        setDialogOpen(true);
                                    }} />
                            </Grid>
                        ))}
                    </>
                )}
            </Grid>

            <Dialog
                title={'غیرفعال کردن نشست'}
                msg={'آیا مطمئن هستید که می‌خواهید این عملیات را انجام دهید؟'}
                open={dialogOpen} handleClose={() => setDialogOpen(false)} handleAccept={handleAcceptInActiveSession} loading={loading} />
        </>
    )
}

export default ActiveSessionsRoute