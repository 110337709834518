import { Box, Skeleton } from "@mui/material";

const ConsultantSkeletonCard = () => {
    return (
        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 4}}>
                <Skeleton variant="rounded" width={40} height={10} />
                <Skeleton variant="circular" width={56} height={56} />
                <Skeleton variant="rounded" width={40} height={10} />
            </Box>
            <Box sx={{width: '80%'}}>
                <Skeleton variant="text" sx={{fontSize: '1rem'}} />
                <Skeleton variant="text" sx={{fontSize: '1rem'}} />
                <Skeleton variant="text" sx={{fontSize: '1rem'}} />
            </Box>
        </Box>
    )
}

export default ConsultantSkeletonCard;