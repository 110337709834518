import { useEffect, useState } from "react";
import { PaginatedDTO } from "../../../DTOs/PaginatedDTO";
import { Grid2 as Grid, Pagination, Paper, Tab, Tabs } from "@mui/material";
import { AccessTime, HighlightOff, TaskAlt, Update } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { cancelConsultation, getConsultations } from "../../../services/consultationService";
import { ConsultationDTO, consultationStatus } from "../../../DTOs/consultation/ConsultationDTO";
import { ConsultationCard, Dialog, ItemSkeletonCard } from "../../components";
import { toast } from "react-toastify";


const ConsultationRoute = () => {
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams({ 'status': 'ns' });
    const [data, setData] = useState<PaginatedDTO<ConsultationDTO>>();
    const [status, setStatus] = useState<consultationStatus | string>(searchParams.get('status')!!);
    const [loading, setLoading] = useState(true);
    const [selectedConsultationId, setSelectedConsultationId] = useState(0);


    const handleChange = async (event: React.SyntheticEvent, newValue: consultationStatus) => {
        setStatus(newValue);
        const currentStatus = searchParams.get('status');
        if (currentStatus === null || currentStatus !== newValue) {
            setSearchParams((prev) => {
                prev.delete('status')
                prev.append('status', newValue);
                prev.delete('page')
                prev.append('page', '1');
                return prev
            })
            await fetchData();
        }
    };

    const fetchData = async () => {
        setLoading(true);
        const response = await getConsultations(searchParams);
        setData(response.data);
        setLoading(false);
    }

    const handlePageChange = async (event: any, page: number) => {
        const currentPage = searchParams.get('page') ?? '1';
        if (currentPage === null || currentPage !== page.toString()) {
            setSearchParams((prev) => {
                prev.delete('page')
                prev.append('page', page.toString());
                return prev
            })
            await fetchData();
        }
    }

    const getPageCounts = () => {
        const pageCount = (data?.count ?? 0) / 25;

        const pageCountInt = Number.parseInt(pageCount.toString());

        if (pageCountInt === 0) {
            return 1
        }
        if (pageCount > pageCountInt) {
            return pageCountInt + 1
        }
        return pageCountInt
    }

    const handleCancelConsultationRequest = async () => {
        setOpenCancelDialog(false);
        setLoading(true)
        try {
            await cancelConsultation(selectedConsultationId)
            toast.success("درخواست شما با موفقیت لغو شد");
            await fetchData();
        } catch (error) {
            toast.error('درخواست شما با خطا مواجه شد لطفا بعدا تلاش کنید.');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Grid container spacing={1}>
                <Grid size={12}>
                    <Paper sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Tabs value={status} onChange={handleChange} visibleScrollbar scrollButtons variant="scrollable">
                            <Tab label='در انتظار شروع' value={consultationStatus.NOT_STARTED} icon={(<AccessTime />)} />
                            <Tab label='در حال برگزاری' value={consultationStatus.PROCESSING} icon={(<Update />)} />
                            <Tab label='به اتمام رسیده' value={consultationStatus.FINISHED} icon={(<TaskAlt />)} />
                            <Tab label='لغو شده' value={consultationStatus.CANCELED} icon={(<HighlightOff />)} />
                        </Tabs>
                    </Paper>
                </Grid>

                {loading ? (
                    <>
                        {Array.from({ length: 8 }, (_, i) => i + 1).map(item => (
                            <Grid size={{ xs: 12, md: 6 }} key={item}>
                                <ItemSkeletonCard />
                            </Grid>
                        ))}
                    </>
                ) : (
                    <>
                        {data?.results.map(c => (
                            <Grid size={{ xs: 12, md: 6 }}>
                                <ConsultationCard consultation={c} key={c.id} handleCancelButtonClicked={() => {
                                    setOpenCancelDialog(true);
                                    setSelectedConsultationId(c.id);
                                }} />
                            </Grid>
                        ))}
                    </>
                )}

                <Grid size={12}>
                    <Paper sx={{ padding: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <Pagination color="primary" count={getPageCounts()} defaultPage={Number.parseInt(searchParams.get('page') ?? '1')} size="large" shape="rounded" onChange={handlePageChange} />
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
                title={'لغو درخواست مشاوره'}
                msg={'آیا مطمئن هستید که می‌خواهید این عملیات را انجام دهید؟'}
                open={openCancelDialog} loading={loading} handleAccept={handleCancelConsultationRequest} handleClose={() => setOpenCancelDialog(false)} />
        </>
    )
}

export default ConsultationRoute;