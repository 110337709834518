import { Grid2 as Grid, Pagination, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { PaginatedDTO } from "../../../DTOs/PaginatedDTO";
import { DiscountDTO } from "../../../DTOs/discounts/DiscountDTO";
import { getDiscounts } from "../../../services/discountService";
import { useSearchParams } from "react-router-dom";
import { DiscountCard, ItemSkeletonCard } from "../../components";

const DiscountsRoute = () => {

    const [data, setData] = useState<PaginatedDTO<DiscountDTO>>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);

    const fetchDiscounts = async () => {
        setLoading(true);
        const result = await getDiscounts(searchParams);
        setData(result.data);
        setLoading(false);
    }

    useEffect(() => {
        fetchDiscounts();
    }, []);

    const handlePageChange = async (event: any, page: number) => {
        const currentPage = searchParams.get('page') ?? '1';
        if (currentPage === null || currentPage !== page.toString()) {
            setSearchParams((prev) => {
                prev.delete('page')
                prev.append('page', page.toString());
                return prev
            })
            await fetchDiscounts();
        }
    }

    const getPageCounts = () => {
        const pageCount = (data?.count ?? 0) / 25;

        const pageCountInt = Number.parseInt(pageCount.toString());

        if (pageCountInt === 0) {
            return 1
        }
        if (pageCount > pageCountInt) {
            return pageCountInt + 1
        }
        return pageCountInt
    }

    return (
        <Grid container spacing={2}>


            {loading ? (
                <>
                    {Array.from({ length: 8 }, (_, i) => i + 1).map(item => (
                        <Grid size={{ xs: 12, md: 6 }} key={item}>
                            <ItemSkeletonCard />
                        </Grid>
                    ))}
                </>
            ) : (
                <>
                    {data?.results.map(d => (
                        <Grid size={{ xs: 12, md: 6 }} key={d.code}>
                            <DiscountCard discount={d} />
                        </Grid>
                    ))}
                </>
            )}

            <Grid size={12}>
                <Paper sx={{ padding: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <Pagination color="primary" count={getPageCounts()} defaultPage={Number.parseInt(searchParams.get('page') ?? '1')} size="large" shape="rounded" onChange={handlePageChange} />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default DiscountsRoute;