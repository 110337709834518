import { useEffect, useState } from "react";
import { PaginatedDTO } from "../../../DTOs/PaginatedDTO";
import { Box, Grid2 as Grid, Pagination, Paper, Tab, Tabs, TextField } from "@mui/material";
import { AccessTime, HighlightOff, TaskAlt, Update } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { cancelConsultation, getConsultations, getConsultationsForConsultant, setResultConsultation } from "../../../services/consultationService";
import { ConsultationDTO, consultationStatus } from "../../../DTOs/consultation/ConsultationDTO";
import { ConsultantConsultationCard, ConsultationCard, Dialog, ItemSkeletonCard } from "../../components";
import { toast } from "react-toastify";
import { ConsultationConsultantDTO } from "../../../DTOs/consultation/ConsultationConsultantDTO";

import * as Yup from 'yup';
import { useFormik } from "formik";
import { ConsultationSetResultDTO } from "../../../DTOs/consultation/ConsultationResultDTO";


interface setResultFormValues {
    result: string,
}

const setResultInitialValues = {
    result: '',
}

const setResultValidationScheme = Yup.object().shape({
    result: Yup.string().required("این مقداراجباری است"),
})

const ConsultantConsultationRoute = () => {
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const [openSetResultDialog, setOpenSetResultDialog] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams({ 'status': 'ns' });
    const [data, setData] = useState<PaginatedDTO<ConsultationConsultantDTO>>();
    const [status, setStatus] = useState<consultationStatus | string>(searchParams.get('status')!!);
    const [loading, setLoading] = useState(false);
    const [selectedConsultationId, setSelectedConsultationId] = useState(0);


    const formik = useFormik({
        initialValues: setResultInitialValues,
        onSubmit: (values: setResultFormValues) => handleSetResultConsultation(values),
        validationSchema: setResultValidationScheme,
    })


    const handleChange = async (event: React.SyntheticEvent, newValue: consultationStatus) => {
        setStatus(newValue);
        const currentStatus = searchParams.get('status');
        if (currentStatus === null || currentStatus !== newValue) {
            setSearchParams((prev) => {
                prev.delete('status')
                prev.append('status', newValue);
                prev.delete('page')
                prev.append('page', '1');
                return prev
            })
            await fetchData();
        }
    };

    const fetchData = async () => {
        setLoading(true);
        const response = await getConsultationsForConsultant(searchParams);
        setData(response.data);
        setLoading(false);
    }

    const handlePageChange = async (event: any, page: number) => {
        const currentPage = searchParams.get('page') ?? '1';
        if (currentPage === null || currentPage !== page.toString()) {
            setSearchParams((prev) => {
                prev.delete('page')
                prev.append('page', page.toString());
                return prev
            })
            await fetchData();
        }
    }

    const getPageCounts = () => {
        const pageCount = (data?.count ?? 0) / 25;

        const pageCountInt = Number.parseInt(pageCount.toString());

        if (pageCountInt === 0) {
            return 1
        }
        if (pageCount > pageCountInt) {
            return pageCountInt + 1
        }
        return pageCountInt
    }

    const handleCancelConsultationRequest = async () => {
        setOpenCancelDialog(false);
        setLoading(true)
        try {
            await cancelConsultation(selectedConsultationId)
            toast.success("درخواست شما با موفقیت لغو شد");
            await fetchData();
        } catch (error) {
            toast.error('درخواست شما با خطا مواجه شد لطفا بعدا تلاش کنید.');
        } finally {
            setLoading(false);
        }
    }

    const handleSetResultConsultation = async (values: setResultFormValues) => {
        setLoading(true);
        try {
            const { result } = values;
            const response = await setResultConsultation(selectedConsultationId, new ConsultationSetResultDTO(result))
            if (response.status === 200) {
                toast.success("نتیجه با موفقیت ثبت شد");
                setOpenSetResultDialog(false);
                await fetchData();
            }
        } catch (error) {
            toast.error('درخواست شما با خطا مواجه شد لطفا بعدا تلاش کنید.');
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Grid container spacing={1}>
                <Grid size={12}>
                    <Paper sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Tabs value={status} onChange={handleChange} visibleScrollbar scrollButtons variant="scrollable">
                            <Tab label='در انتظار شروع' value={consultationStatus.NOT_STARTED} icon={(<AccessTime />)} />
                            <Tab label='در حال برگزاری' value={consultationStatus.PROCESSING} icon={(<Update />)} />
                            <Tab label='به اتمام رسیده' value={consultationStatus.FINISHED} icon={(<TaskAlt />)} />
                            <Tab label='لغو شده' value={consultationStatus.CANCELED} icon={(<HighlightOff />)} />
                        </Tabs>
                    </Paper>
                </Grid>

                {loading ? (
                    <>
                        {Array.from({ length: 8 }, (_, i) => i + 1).map(item => (
                            <Grid size={{ xs: 12, md: 6 }} key={item}>
                                <ItemSkeletonCard />
                            </Grid>
                        ))}
                    </>
                ) : (
                    <>
                        {data?.results.map(c => (
                            <Grid size={{ xs: 12, md: 6 }}>
                                <ConsultantConsultationCard consultation={c} key={c.id}
                                    handleCancelButtonClicked={() => {
                                        setOpenCancelDialog(true);
                                        setSelectedConsultationId(c.id);
                                    }}
                                    handleSetResultButtonPressed={() => {
                                        setOpenSetResultDialog(true);
                                        setSelectedConsultationId(c.id);
                                    }}
                                />
                            </Grid>
                        ))}
                    </>
                )}

                <Grid size={12}>
                    <Paper sx={{ padding: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <Pagination color="primary" count={getPageCounts()} defaultPage={Number.parseInt(searchParams.get('page') ?? '1')} size="large" shape="rounded" onChange={handlePageChange} />
                    </Paper>
                </Grid>
            </Grid>
            <Dialog
                title={'لغو درخواست مشاوره'}
                msg={'آیا مطمئن هستید که می‌خواهید این عملیات را انجام دهید؟'}
                open={openCancelDialog} loading={loading} handleAccept={handleCancelConsultationRequest} handleClose={() => setOpenCancelDialog(false)} />

            <Dialog
                title={'ثبت نتیجه'}
                msg={'لطفا تمامی مقادیر را وارد کنید'}
                open={openSetResultDialog} loading={loading} handleAccept={formik.submitForm} handleClose={() => setOpenSetResultDialog(false)}>

                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <TextField name="result" multiline label={'نتیجه'} fullWidth
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.result && Boolean(formik.errors.result)}
                            helperText={formik.touched.result && formik.errors.result} />
                    </Box>
                </form>
            </Dialog>
        </>
    )
}

export default ConsultantConsultationRoute;