import { Container, Grid2 as Grid, Pagination, Paper, Typography } from "@mui/material";
import { PaginatedDTO } from "../../DTOs/PaginatedDTO";
import { SpecialtyDTO } from "../../DTOs/specialty/SpecialtyDTO";
import { useEffect, useState } from "react";
import { getSpecialties } from "../../services/specialtyService";
import { Item, ItemSkeletonCard } from "../components";
import { useSearchParams } from "react-router-dom";

const SpecialtiesRoute = () => {

    const [data, setData] = useState<PaginatedDTO<SpecialtyDTO>>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        const response = await getSpecialties(searchParams);
        setData(response.data);
    }


    const handlePageChange = async (event: any, page: number) => {
        const currentPage = searchParams.get('page') ?? '1';
        if (currentPage === null || currentPage !== page.toString()) {
            setSearchParams((prev) => {
                prev.delete('page')
                prev.append('page', page.toString());
                return prev
            })
            await fetchData();
        }
    }

    const getPageCounts = () => {
        const pageCount = (data?.count ?? 0) / 25;

        const pageCountInt = Number.parseInt(pageCount.toString());

        if (pageCountInt === 0) {
            return 1
        }
        if (pageCount > pageCountInt) {
            return pageCountInt + 1
        }
        return pageCountInt
    }

    useEffect(() => {
        setLoading(true);
        fetchData()
        setLoading(false);
    }, []);

    return (
        <Container>
            <Grid container>
                <Grid size={12}>
                    <Grid container spacing={1}>

                        {loading ? (
                            <>
                                {Array.from({ length: 21 }, (_, i) => i + 1).map(item => (
                                    <Grid size={{ xs: 12, md: 6, lg: 4 }} key={item}>
                                        <ItemSkeletonCard />
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <>
                                {data?.results.map(s => (
                                    <Grid size={{ xs: 12, md: 6, lg: 4 }} key={s.id}>
                                        <Item title={s.title} description={s.description} img={s.image} key={s.id} to={`/consultants?specialty=${s.id}`} />
                                    </Grid>
                                ))}
                            </>
                        )}

                        <Grid size={12}>
                            <Paper sx={{ padding: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <Pagination color="primary" count={getPageCounts()} defaultPage={Number.parseInt(searchParams.get('page') ?? '1')} size="large" shape="rounded" onChange={handlePageChange} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}


export default SpecialtiesRoute;