import { Autocomplete, Box, Collapse, Container, Divider, Grid2 as Grid, IconButton, MenuItem, Modal, Pagination, Paper, TextField, Typography } from "@mui/material"
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button, ConsultantCard, ConsultantSkeletonCard } from "../components"
import { useEffect, useState } from "react";
import { consultantSearch } from "../../services/consultantService";
import { ConsultantDetailDTO } from "../../DTOs/consultant/ConsultantDetailDTO";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { SpecialtyDTO } from "../../DTOs/specialty/SpecialtyDTO";
import { getSpecialties } from "../../services/specialtyService";
import { PaginatedDTO } from "../../DTOs/PaginatedDTO";


const orderingOptions = [
    { value: '', label: 'بدون انتخاب' },
    { value: '-created_at', label: 'جدیدترین' },
    { value: 'rating', label: 'محبوب‌ترین' },
]

const onlineOptions = [
    { value: '', label: 'بدون انتخاب' },
    { value: 'false', label: 'آفلاین' },
    { value: 'true', label: 'آنلاین' },
]

const ConsultantRoute = () => {

    const [consultantsData, setConsultantsData] = useState<PaginatedDTO<ConsultantDetailDTO>>();
    const [specialties, setSpecialties] = useState<SpecialtyDTO[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [filterCollapse, setFilterCollapse] = useState(false);

    const initialValues = {
        is_online: searchParams.get("is_online") || "",
        ordering: searchParams.get("ordering") || "",
        search: searchParams.get("search") || "",
        specialty: searchParams.get("specialty") || "",
    };

    const formik = useFormik({ initialValues: initialValues, onSubmit: (values) => handleFilterSubmit(values) })


    const getConsultantsAsync = async (param?: any) => {
        setLoading(true);
        try {
            const response = await consultantSearch(undefined, param || searchParams);
            setConsultantsData(response.data);
        } catch (error) {

        }
    }

    const getSpecialtiesAsync = async () => {
        const response = await getSpecialties();
        setSpecialties(response.data.results);
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getConsultantsAsync();
            await getSpecialtiesAsync();
            setLoading(false);
        }

        fetchData();

    }, [])

    const handleFilterSubmit = async (values: any) => {
        const param = {
            search: values.search,
            is_online: values.is_online,
            ordering: values.ordering,
            specialty: values.specialty,
        }
        setLoading(true)
        setSearchParams(param);
        await getConsultantsAsync(param)
        setLoading(false)
    };

    const filterCollapseToggle = async () => {
        setFilterCollapse((prev) => !prev);
    }

    const handlePageChange = async (event: any, page: number) => {
        const currentPage = searchParams.get('page') ?? '1';
        if (currentPage === null || currentPage !== page.toString()) {
            setSearchParams((prev) => {
                prev.delete('page')
                prev.append('page', page.toString());
                return prev
            })
            await getConsultantsAsync();
        }
    }

    const getPageCounts = () => {
        const pageCount = (consultantsData?.count ?? 0) / 25;

        const pageCountInt = Number.parseInt(pageCount.toString());

        if (pageCountInt === 0) {
            return 1
        }
        if (pageCount > pageCountInt) {
            return pageCountInt + 1
        }
        return pageCountInt
    }

    return (
        <Container fixed>
            <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 4, lg: 3 }}>
                    <form onSubmit={formik.handleSubmit}>
                        <Paper sx={{ padding: 2, position: 'sticky', top: '10px' }}>
                            <Box gap={1} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontFamily={'Kalameh'}>فیلتر مشاوران</Typography>
                                <IconButton onClick={filterCollapseToggle}>
                                    {filterCollapse ? (<KeyboardArrowDown />) : (<KeyboardArrowUp />)}
                                </IconButton>
                            </Box>
                            <Collapse in={!filterCollapse}>
                                <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column', marginTop: 2 }}>
                                    <TextField label={"نام و نام خانوادگی"} name="search" value={formik.values.search} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    <TextField label={"مرتب سازی"} select name="ordering" value={formik.values.ordering} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                        {orderingOptions.map(option => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField label={"وضعیت آنلاین بودن"} select name="is_online" value={formik.values.is_online} onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                        {onlineOptions.map(option => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </TextField>
                                    <Autocomplete
                                        options={specialties}
                                        getOptionLabel={(option) => option.title}
                                        onChange={(event, newValues) => formik.setFieldValue('specialty', newValues?.id)}
                                        onBlur={formik.handleBlur}
                                        // TODO: get value from params and show in field
                                        renderInput={(params) => (
                                            <TextField label={'تخصص'} {...params} />
                                        )}
                                    />
                                    <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
                                        <Grid size={12}>
                                            <Button variant="contained" type="submit" fullWidth loading={loading}>اعمال فیلتر</Button></Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Paper>
                    </form>
                </Grid>
                <Grid size={{ xs: 12, md: 8, lg: 9 }}>
                    <Grid container spacing={2}>
                        {loading ? (
                            <>
                                {Array.from({ length: 9 }, (_, i) => i + 1).map(item => (
                                    <Grid size={{ xs: 12, md: 6, lg: 4 }} key={item}>
                                        <ConsultantSkeletonCard />
                                    </Grid>
                                ))}

                            </>
                        ) : (
                            <>
                                {consultantsData?.results.map((consultant) => (
                                    <Grid size={{ xs: 12, md: 6, lg: 4 }} key={consultant.id}>
                                        <ConsultantCard consultant={consultant} />
                                    </Grid>
                                ))}
                            </>
                        )}

                        <Grid size={12}>
                            <Paper sx={{ padding: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <Pagination color="primary" count={getPageCounts()} defaultPage={Number.parseInt(searchParams.get('page') ?? '1')} size="large" shape="rounded" onChange={handlePageChange} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ConsultantRoute;