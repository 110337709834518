import { useEffect, useRef, useState } from "react";
import {
    Paper,
    Typography,
    Box,
    List,
    Avatar,
    Container,
    IconButton,
} from "@mui/material";
import { Attachment, Close, Send as SendIcon } from "@mui/icons-material";
import { Button, Dialog, MessageField } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../../services/tokenStorageService";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { toast } from "react-toastify";
import { uploadFile } from "../../../services/consultationService";
import { grey } from "@mui/material/colors";

const MessageItem = ({ message, isYou }: any) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: isYou ? "flex-start" : "flex-end",
                mb: 1.5,
                px: 1,
            }}
        >
            <Box
                sx={{
                    maxWidth: "75%",
                }}
            >
                <Paper
                    sx={{
                        display: 'flex', flexDirection: 'column',
                        backgroundColor: isYou ? "primary.container" : "paper.default",
                        px: 2, py: 1.2, gap: 1
                    }}>

                    {message.message_type === 'file' && (
                        <Link to={`${API_BASE_URL}${message.file_url}`} target="_blank" style={{textDecoration: 'none'}}>
                            <Paper sx={{ p: 1, display: 'flex', gap: 1, alignItems: 'center', bgcolor: grey[200] }}>
                                <Typography variant="caption">فایل اپلود شده</Typography>
                                <Attachment />
                            </Paper>
                        </Link>
                    )}

                    <Typography variant="body2">{message.message}</Typography>
                    <Typography variant="caption" sx={{ opacity: 0.8 }}>
                        {new Date(message.timestamp).toLocaleTimeString()}
                    </Typography>
                </Paper>
            </Box>
        </Box>
    );
};


const SOCKET_SERVER_URL = process.env.REACT_APP_WS_URL;
const API_BASE_URL = process.env.REACT_APP_BASE_URL;


type OtherUser = {
    first_name: string,
    last_name: string,
    img: string,
}

type Message = {
    message: string,
    message_type: string,
    file_url?: string,
    sender?: number,
    timestamp: string,
}

const TextChatRoute = () => {
    const navigate = useNavigate();
    const { consultationCode } = useParams();
    const wsRef = useRef<WebSocket | null>(null);


    const { account } = useAppSelector((state) => state.account);
    const [otherUser, setOtherUser] = useState<any>(undefined);

    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState<any[]>([]);
    const [file, setFile] = useState<File | null>(null);

    const [showCloseConsultationDialog, setShowCloseConsultationDialog] = useState(false);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const sendMessage = async () => {
        if (file) {
            await sendFileUrl()
        } else {
            if (wsRef.current && message) {
                const newMessage: Message = { message_type: 'message', message, sender: account?.id, file_url: undefined, timestamp: new Date().toISOString() }
                wsRef.current.send(JSON.stringify(newMessage));
                setMessage('');
                setMessages((prevMessages) => [...prevMessages, newMessage]); // Append new message
            }
        }
    };

    const sendFileUrl = async () => {
        const fileURL = await uploadFileToServer()

        if (wsRef.current && message) {
            const newMessage = { message_type: 'file', message, file_url: fileURL, sender: account?.id, timestamp: new Date().toISOString() }
            wsRef.current.send(JSON.stringify(newMessage))
            setMessage('');
            setFile(null);
            setMessages((prevMessages) => [...prevMessages, newMessage]); // Append new 
        }
    }

    const uploadFileToServer = async () => {
        if (file) {
            const formData = new FormData()
            formData.append('file', file)
            try {
                const response = await uploadFile(formData);
                return response.data.file_url
            } catch (e) {
                console.log(e);
            }
        }
        return null
    }


    const closeConsultationMessage = () => {
        if (wsRef.current) {
            const newMessage = { message_type: 'closeConsultationRequest' }
            wsRef.current.send(JSON.stringify(newMessage));
            toast.success("درخواست شما برای طرف مقابل ارسال شد")
        }
    }

    const closeConsultation = () => {
        if (wsRef.current) {
            const newMessage = { message_type: 'closeConsultation' }
            wsRef.current.send(JSON.stringify(newMessage));
            toast.info("اتمام جلسه")
            navigate(-1);
        }
    }

    useEffect(() => {
        wsRef.current = new WebSocket(`${SOCKET_SERVER_URL}chat/${consultationCode}/?token=${getToken()}`);
        wsRef.current.onopen = () => console.log("Connected to signaling server");
        wsRef.current.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.previous_messages) {
                setMessages(data.previous_messages); // Set previous messages on first connection
            } else if (data.type === 'chat_message') {
                setMessages((prevMessages) => [...prevMessages, data]); // Append new message
            }
            if (data.type === 'data') {
                const accountData = {
                    firstName: data.first_name,
                    lastName: data.last_name,
                    img: data.img,
                }
                setOtherUser(accountData);
            }

            if (data.close_consultation_request) {
                setShowCloseConsultationDialog(true);
            }

            if (data.close_consultation) {
                toast.info("اتمام جلسه");
                navigate(-1);
            }
        };

        return () => {
            wsRef.current?.close()
            wsRef.current = null;
        }
    }, [])
    return (
        <Container sx={{ p: 1, display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
            <Paper sx={{ p: 3, display: 'flex', justifyContent: 'space-between', width: 1 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', }}>
                    {otherUser !== undefined ? (
                        <>
                            <Avatar
                                sx={{ width: 56, height: 56, }}
                                src={`${API_BASE_URL}${otherUser.img}`} />
                            <Typography>{otherUser.firstName} {otherUser.lastName}</Typography>
                        </>
                    ) : (
                        <>
                            <Avatar
                                sx={{ width: 56, height: 56, }}
                                src={undefined} />
                            <Typography>درحال بارگیری اطلاعات...</Typography>
                        </>
                    )}
                </Box>
                <Button color="error" variant="outlined" startIcon={(<Close />)} onClick={closeConsultationMessage}>اتمام جلسه</Button>
            </Paper>

            <Box sx={{ flex: 1, overflowY: 'auto', width: 1 }}>
                <List sx={{ width: 1 }}>
                    {messages.map(m => (
                        <MessageItem message={m} isYou={m.sender === account?.id} />
                    ))}
                </List>
            </Box>

            {file && (
                <Paper sx={{ p: 1, width: 1, display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => setFile(null)}>
                        <Close />
                    </IconButton>
                    <Typography>فایل انتخاب شده</Typography>
                </Paper>
            )}
            <Paper sx={{ p: 3, display: 'flex', justifyContent: 'space-between', width: 1, gap: 2 }}>
                <IconButton onClick={sendMessage} color="primary">
                    <SendIcon />
                </IconButton>
                <IconButton component='label' >
                    <Attachment />
                    <input hidden type="file" onChange={handleFileChange} />
                </IconButton>
                <MessageField
                    placeholder={'پیامی ارسال کنید'}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    fullWidth
                />
            </Paper>


            <Dialog open={showCloseConsultationDialog} title="اتمام جلسه" msg="طرف مقابل درخواست اتمام جلسه را دارد"
                handleAccept={closeConsultation}
                handleClose={() => setShowCloseConsultationDialog(false)} />
        </Container>
    )
}
export default TextChatRoute;