import { Box, Container, Grid2 as Grid, TextField, Typography } from "@mui/material"

import { ConsultantCard, ConsultantSkeletonCard, Item, ItemSkeletonCard, ProtectedBlur, SearchField, Slider, SubmitPrescriptionDialog, UrgentConsultationDialog } from "../components";
import ListHeader from "../components/ListHeader/ListHeader";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSpecialties } from "../../services/specialtyService";
import { SpecialtyDTO } from "../../DTOs/specialty/SpecialtyDTO";
import { consultantSearch } from "../../services/consultantService";
import { ConsultantDetailDTO } from "../../DTOs/consultant/ConsultantDetailDTO";
import { SwiperSlide } from "swiper/react";
import CountUp from "react-countup";
import { QDTO } from "../../DTOs/generalStats/QDTO";
import { generalStats } from "../../services/generalStatsService";
import { AdDTO } from "../../DTOs/ad/AdDTO";
import { getAds } from "../../services/adService";
import { useAppSelector } from "../../hooks/reduxHooks";


const HomeRoute = () => {

    const [last8Specialties, setLast8Specialties] = useState<SpecialtyDTO[]>([]);
    const [onlineConsultants, setOnlineConsultants] = useState<ConsultantDetailDTO[]>([]);
    const [bestConsultants, setBestConsultants] = useState<ConsultantDetailDTO[]>([]);
    const [lastConsultants, setLastConsultants] = useState<ConsultantDetailDTO[]>([]);
    const [ads, setAds] = useState<AdDTO[]>();
    const [Q, setQ] = useState<QDTO>({ consultants_count: 0, consultations_count: 0, prescriptions_count: 0 });

    const isLoggedIn = useAppSelector((state) => state.account.isLoggedIn);
    const [loading, setLoading] = useState(true);


    const [openPrescriptionDialog, setOpenPrescriptionDialog] = useState(false);
    const [openUrgentConsultationDialog, setOpenUrgentConsultationDialog] = useState(false);

    const navigate = useNavigate();

    const getSpecialtiesAsync = async () => {
        const response = await getSpecialties()
        setLast8Specialties(response.data.results.slice(0, 8));
    }

    const getOnlineConsultantsAsync = async () => {
        const response = await consultantSearch(4, { is_online: true })
        setOnlineConsultants(response.data.results);
    }

    const getBestConsultantsAsync = async () => {
        const response = await consultantSearch(4, { ordering: 'rating' });
        setBestConsultants(response.data.results);
    }

    const getLastConsultantsAsync = async () => {
        const response = await consultantSearch(4, { ordering: '-created_at' });
        setLastConsultants(response.data.results);
    }

    const gethAdAsync = async () => {
        const response = await getAds({ ad_position: 'h' });
        setAds(response.data);
    }

    const loadGeneralStatsAsync = async () => {
        const response = await generalStats();
        setQ(response.data)
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getSpecialtiesAsync();
            await getOnlineConsultantsAsync();
            await getBestConsultantsAsync();
            await getLastConsultantsAsync();
            await loadGeneralStatsAsync();
            await gethAdAsync();
            setLoading(false);
        }

        fetchData();

    }, [])

    const handleSearchSubmit = (event: any) => {
        event.preventDefault()

        navigate(`/consultants?search=${event.target.search.value}`)

    }

    return (
        <>
            <Box sx={{ background: 'white', pt: 10, pb: 10 }}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid size={{ xs: 12, lg: 6 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={require('../../assets/images/landing.png')} style={{ width: '100%' }} alt="" />
                        </Grid>
                        <Grid size={{ xs: 12, lg: 6 }} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 1 }}>
                            <Typography variant={'h4'} fontFamily={"Kalameh"} color="primary">با دکترمن</Typography>
                            <Typography variant={'h4'} fontFamily={"Kalameh"}>دکتر همیشه همراهته :)</Typography>
                            <Typography variant={'subtitle2'}>
                                هر زمان و هر جا که باشی، راحت نوبت بگیر، مشاوره بگیر و خیالت از سلامتت راحت باشه!
                                بهترین پزشکان، فقط یک کلیک با تو فاصله دارن.
                            </Typography>

                            <Box sx={{ mt: 2, width: '100%' }}>
                                <form onSubmit={handleSearchSubmit}>
                                    <SearchField placeholder={"جستجو"} fullWidth name="search" />
                                </form>
                            </Box>
                        </Grid>
                        <Grid size={12} sx={{ display: 'flex', mt: 3, justifyContent: 'space-around', backgroundColor: 'primary.container', p: 3, borderRadius: '10px', color: 'primary.main' }}>
                            <CountUp end={Q.consultants_count} duration={5} suffix=" مشاور" />
                            <Typography>|</Typography>
                            <CountUp end={Q.consultations_count} duration={5} suffix=" مشاوره" />
                            <Typography>|</Typography>
                            <CountUp end={Q.prescriptions_count} duration={5} suffix=" تجویز نسخه" />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {loading ? (
                <Container sx={{ mt: 2 }}>
                    <ListHeader title="مشاوران آنلاین" showButton buttonLinkTo={"/consultants?is_online=true"} />
                    <Slider>
                        {[0, 1, 2, 3].map((item) => (
                            <SwiperSlide key={item}>
                                <ConsultantSkeletonCard />
                            </SwiperSlide>
                        ))}
                    </Slider>

                    <ListHeader title="دسترسی سریع" />
                    <Grid container spacing={2}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <ItemSkeletonCard />
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <ItemSkeletonCard />
                        </Grid>
                    </Grid>

                    <ListHeader title="برترین مشاوران" showButton buttonLinkTo={"/consultants?ordering=rating"} />
                    <Slider>
                        {[0, 1, 2, 3].map((item) => (
                            <SwiperSlide key={item}>
                                <ConsultantSkeletonCard />
                            </SwiperSlide>
                        ))}
                    </Slider>

                    <ListHeader title="" />
                    <Box sx={{ m: 1 }}>
                        <Slider sxPerView={1} mdPerView={2} xlPerView={2}>
                            {ads?.map(a => (
                                <SwiperSlide>
                                    <ItemSkeletonCard />
                                </SwiperSlide>
                            ))}
                        </Slider>
                    </Box>

                    <ListHeader title="تخصص ها" showButton buttonLinkTo={'/specialties'} />
                    <Grid container spacing={2}>
                        {[0, 1, 2, 3, 4, 5, 6, 7].map(item => (
                            <Grid size={{ xs: 12, md: 6, xl: 3 }} key={item}>
                                <ItemSkeletonCard />
                            </Grid>
                        ))}
                    </Grid>

                    <ListHeader title="جدیدترین مشاوران" showButton buttonLinkTo={"/consultants?ordering=-created_at"} />
                    <Slider>
                        {[0, 1, 2, 3].map((item) => (
                            <SwiperSlide key={item}>
                                <ConsultantSkeletonCard />
                            </SwiperSlide>
                        ))}
                    </Slider>
                </Container>
            ) : (
                <Container sx={{ mt: 2 }}>
                    <ListHeader title="مشاوران آنلاین" showButton buttonLinkTo={"/consultants?is_online=true"} />
                    <Slider>
                        {onlineConsultants.map((consultant) => (
                            <SwiperSlide key={consultant.id}>
                                <ConsultantCard consultant={consultant} />
                            </SwiperSlide>
                        ))}
                    </Slider>

                    <ListHeader title="دسترسی سریع" />
                    <Grid container spacing={2}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <ProtectedBlur isBlur={isLoggedIn}>
                                <Item title="مشاوره ی فوری" onClick={() => setOpenUrgentConsultationDialog(true)} img={require('../../assets/images/chat.png')} />
                            </ProtectedBlur>
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <ProtectedBlur isBlur={isLoggedIn}>
                                <Item title="تجویز نسخه" onClick={() => setOpenPrescriptionDialog(true)} img={require('../../assets/images/medicine.png')} />
                            </ProtectedBlur>
                        </Grid>
                    </Grid>

                    <ListHeader title="برترین مشاوران" showButton buttonLinkTo={"/consultants?ordering=rating"} />
                    <Slider>
                        {bestConsultants.map((consultant) => (
                            <SwiperSlide key={consultant.id}>
                                <ConsultantCard consultant={consultant} />
                            </SwiperSlide>
                        ))}
                    </Slider>

                    <ListHeader title="" />
                    <Box sx={{ m: 1 }}>
                        <Slider sxPerView={1} mdPerView={2} xlPerView={2}>
                            {ads?.map(a => (
                                <SwiperSlide>
                                    <Link to={a.link} target="_blank">
                                        <img src={a.banner} alt={a.title} style={{ borderRadius: '10px', objectFit: 'cover', width: '100%' }} />
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </Slider>
                    </Box>

                    <ListHeader title="تخصص ها" showButton buttonLinkTo={'/specialties'} />
                    <Grid container spacing={2}>
                        {last8Specialties.map(specialty => (
                            <Grid size={{ xs: 12, md: 6, xl: 3 }} key={specialty.id}>
                                <Item key={specialty.id} title={specialty.title} to={`/consultants?specialty=${specialty.id}`} img={specialty.image} />
                            </Grid>
                        ))}
                    </Grid>

                    <ListHeader title="جدیدترین مشاوران" showButton buttonLinkTo={"/consultants?ordering=-created_at"} />
                    <Slider>
                        {lastConsultants.map((consultant) => (
                            <SwiperSlide key={consultant.id}>
                                <ConsultantCard consultant={consultant} />
                            </SwiperSlide>
                        ))}
                    </Slider>
                </Container>
            )}


            <SubmitPrescriptionDialog open={openPrescriptionDialog} handleClose={() => setOpenPrescriptionDialog(false)} />
            <UrgentConsultationDialog open={openUrgentConsultationDialog} handleClose={() => setOpenUrgentConsultationDialog(false)} />
        </>
    )

}

export default HomeRoute;