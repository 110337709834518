import { InputBase, InputBaseProps } from "@mui/material";
import styles from './messagefield.module.css';
import { grey } from '@mui/material/colors';;

interface SearchFieldProps extends InputBaseProps { }


const MessageField = (props: SearchFieldProps) => {
    return (
        <InputBase {...props} className={styles.searchfield}
            sx={{
                backgroundColor: grey[200],
            }}
        />
    )
}

export default MessageField;