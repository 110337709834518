import { AccountBalanceWallet, Refresh } from "@mui/icons-material";
import { Box, Grid2 as Grid, IconButton, InputAdornment, Pagination, Paper, TextField, Typography } from "@mui/material";
import { Button, ItemSkeletonCard, TransactionCard } from "../../components";
import { createPaymentGateway, getWalletBalance, getWalletTransactions, walletTransactionsVerify } from "../../../services/walletService";
import { useEffect, useState } from "react";
import { PaginatedDTO } from "../../../DTOs/PaginatedDTO";
import { TransactionDTO } from "../../../DTOs/wallet/TransactionDTO";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { toast } from "react-toastify";


interface IncreaseBalanceFormValues {
    amount: number,
}


const validationSchema = Yup.object().shape({
    amount: Yup.number().min(10_000, "حداقل افزایش موجودی 10 هزار تومان است").max(100_000_000, "حداکثر افزایش موجودی 100 میلیون تومان است").required("این مقدار اجباری است"),
})

const WalletRoute = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState(0);
    const [withdrawalBalance, setWithdrawalBalance] = useState<number>(0);
    const [transactionData, setTransactionData] = useState<PaginatedDTO<TransactionDTO>>();

    const navigate = useNavigate();

    const initialValues = {
        amount: 0,
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values: IncreaseBalanceFormValues) => handleIncreaseBalanceSubmit(values),
        validationSchema: validationSchema,
    })


    const handleIncreaseBalanceSubmit = async (values: IncreaseBalanceFormValues) => {
        setLoading(true);
        try {
            const result = await createPaymentGateway(values.amount);
            formik.resetForm();
            navigate(result.data.payment_gateway, { replace: true })
            window.open(result.data.payment_gateway, '_self')
        } catch (error) {
            toast.error("عملیات درخواستی با خطا مواجه شد لطفا بعدا تلاش کنید")
        } finally {
            setLoading(false)
        }
    }



    const fetchBalance = async () => {
        const { data } = await getWalletBalance();
        setBalance(data.balance);
        setWithdrawalBalance(data.withdrawal_balance);
    }
    const fetchTransactions = async () => {
        const { data } = await getWalletTransactions(searchParams);
        setTransactionData(data);
    }

    const handleRefreshPress = async () => {
        setLoading(true)
        setBalance(0);
        setWithdrawalBalance(0);
        setTransactionData(undefined);

        await walletTransactionsVerify()

        fetchData();
    }

    const fetchData = async () => {
        setLoading(true)
        await fetchBalance()
        await fetchTransactions();
        setLoading(false)
    }

    const handlePageChange = async (event: any, page: number) => {
        const currentPage = searchParams.get('page') ?? '1';
        if (currentPage === null || currentPage !== page.toString()) {
            setSearchParams((prev) => {
                prev.delete('page')
                prev.append('page', page.toString());
                return prev
            })
            setLoading(true);
            await fetchTransactions();
            setLoading(false);
        }
    }

    const getPageCounts = () => {
        const pageCount = (transactionData?.count ?? 0) / 25;

        const pageCountInt = Number.parseInt(pageCount.toString());

        if (pageCountInt === 0) {
            return 1
        }
        if (pageCount > pageCountInt) {
            return pageCountInt + 1
        }
        return pageCountInt
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Paper sx={{ width: 1, height: 1, display: 'flex', gap: 2, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: { xs: 1, md: 0 } }}>
                        <Typography>شارژ کیف پول</Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField label={'مبلغ'} type="number"
                                    name="amount"
                                    value={formik.values.amount}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    helperText={formik.touched.amount && formik.errors.amount}
                                    error={formik.touched.amount && Boolean(formik.errors.amount)}
                                    slotProps={{
                                        input: {
                                            endAdornment: <InputAdornment position="end">تومان</InputAdornment>,
                                        },
                                    }} />
                                <Button variant="outlined" type="submit" loading={loading}>شارژ حساب</Button>
                            </Box>
                        </form>
                    </Paper>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <Paper sx={{ backgroundColor: 'primary.main', p: 4, color: 'white', borderRadius: '10px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                                <AccountBalanceWallet />
                                <Box>
                                    <Typography fontFamily={'Kalameh'}>موجودی</Typography>
                                    <Typography fontFamily={'Kalameh'}>{balance} تومان</Typography>
                                    <Typography variant="caption">موجودی قابل برداشت: {withdrawalBalance} تومان</Typography>
                                </Box>
                            </Box>
                            <IconButton sx={{ color: 'white' }} onClick={handleRefreshPress} loading={loading}>
                                <Refresh />
                            </IconButton>
                        </Box>
                    </Paper>
                </Grid>
                <Grid size={12}>
                    <Grid container spacing={2}>
                        {loading ? (
                            <>
                                {Array.from({ length: 8}, (_, i) => i + 1).map(item => (
                                    <Grid size={{ xs: 12, md: 6 }} key={item}>
                                        <ItemSkeletonCard />
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <>
                                {transactionData?.results.map(t => (
                                    <Grid size={{ xs: 12, md: 6 }} key={t.id}>
                                        <TransactionCard transaction={t} />
                                    </Grid>
                                ))}
                            </>
                        )}

                    </Grid>
                </Grid>
                <Grid size={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Paper sx={{ padding: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <Pagination color="primary" count={getPageCounts()} defaultPage={Number.parseInt(searchParams.get('page') ?? '1')} size="large" shape="rounded" onChange={handlePageChange} />
                    </Paper>
                </Grid>
            </Grid>
        </Box >
    )
}

export default WalletRoute;